import logo from './banner.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>BKAKG ASSOCIATION - BHAVANI</p>
        <div className='singleView'>
          <div className='loginButton'>
            <p className='buttonText'>LOGIN</p>
          </div>
          <div className='downloadButton'>
          <p className='buttonText'>Download the Mobile App</p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
